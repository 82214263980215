import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
export default new Vuex.Store({
    state: {
        /* 当前页面的位置 */
        activeIndex:'1',
        /*是否是新闻详情*/
        newsIndex: false,
        /*导航栏是否固定*/
        isIndex: 0,
        
    },
    mutations: {          
        setIndexInfo(state,record){
            state.activeIndex=record.data || state.activeIndex;
            state.newsIndex= record.news;
            state.isIndex = record.isIndex ;
        },
    },
    actions: {
        refreshIndexInfo({commit},record){
            commit('setIndexInfo',record)
        }
    },
    modules: {}
})

<template>
    <div class="big-container">
        <div class="container">
            <img
              v-if="!isNews"
              class="logo-style"
              src="@/assets/image/logo.svg"
            />
            <el-menu v-if="!isNews" :default-active="activeIndex" class="el-menustyle" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1" class="el-item" @click="jumpRout('/index','1')">首页</el-menu-item>
                <el-submenu index="2" class="el-item" :style="{'border-bottom': activeIndex === '2'?'0.2rem solid #11A84F' : '0rem solid'}" @click.native="jumpRout('/industry-scheme','2')">
                    <template slot="title">行业方案</template>
                    <div class="industry-scheme">
                        <div class="industry-scheme-item">
                            <div class="industry-scheme-head"  @click="jumpRout('/industry-scheme','2')"><span>快消零售</span></div>
                            <div class="industry-scheme-title" >
                                <div class="item" @click="jumpRout('/digital-production','2')"><span>数字化生产</span></div>
                                <div class="item" @click="jumpRout('/intelligent-warehousing','2')"><span>智能仓储</span></div>
                                <div class="item" @click="jumpRout('/APS-advanced-scheduling','2')"><span>APS高级排程</span></div>
                                <div class="item" @click="jumpRout('/purchase-collaboration','2')"><span>采购协同</span></div>
                                <div class="item" @click="jumpRout('/DPM-digital-Sheet','2')"><span>DPM数字化工单</span></div>
                                <div class="item" @click="jumpRout('/safety-observation','2')"><span>在线安全观察</span></div>
                            </div>
                        </div>
                         <div class="industry-scheme-item" style="margin-left:0">
                            <div class="industry-scheme-head" @click="jumpRout('/medical-health','2')"><span>医疗健康</span></div>
                            <div class="industry-scheme-title">
                                <div class="item" style="width:50%" @click="jumpRout('/sales-effectiveness','2')"><span>销售效能管理</span></div>
                                <div class="item" style="width:50%" @click="jumpRout('/academic-conference','2')"><span>学术会议管理</span></div>
                                <div class="item" style="width:50%" @click="jumpRout('/sales-operation','2')"><span>销售运营管理</span></div>
                                <div class="item" style="width:50%" @click="jumpRout('/market-access','2')"><span>市场准入与商务运营</span></div>
                            </div>
                         </div>
                    </div>
                </el-submenu>
                <el-submenu index="8" class="el-item" :style="{'border-bottom': activeIndex === '8'?'0.2rem solid #11A84F' : '0rem solid'}">
                    <template slot="title">产品</template>
                    <div class="industry-scheme">
                        <div class="check-scheme-item-pro">
                            <div class="product-card" @click="handleJumpTo()">
                                <div class="check-scheme-head-img">
                                    <img src="@/assets/image/index/vticket.svg" alt="">
                                </div>
                                <span class="icon-value-vticket">不一样的服务管理平台</span>
                            </div>
                            <div class="product-card" @click="handleJumpTo()">
                                <div class="check-scheme-head-img-vteam">
                                    <img src="@/assets/image/index/vproject.svg" alt="">
                                </div>
                                <span class="icon-value-vteam">高效的项目管理平台</span>
                            </div>
                        </div>
                    </div>
                </el-submenu>
                <el-submenu index="3" class="el-item" :style="{'border-bottom': activeIndex === '3'?'0.2rem solid #11A84F' : '0rem solid'}">
                    <template slot="title">精选案例</template>
                    <div class="industry-scheme">
                      <div class="industry-scheme-item industry-solution-wrap">
                        <div class="industry-solution">
                          <div class="industry-solution-title">精选案例</div>
                          <div class="industry-solution-content">
                            <div class="industry-solution-content-item" style="flex-basis: 66%;">
                              <div class="check-scheme-head" style="margin-left: 0;">快消零售</div>
                              <div class="industry-scheme-title" >
                                <div class="item" style="width: 50%" @click="jumpRout('/selected-warehousing','3')">
                                  <span>
                                    智能仓储
                                    <!-- <div class="explain">- 全球某知名饮料制造商</div> -->
                                  </span>
                                </div>
                                <div class="item" style="width: 50%" @click="jumpRout('/selected-iDAS','3')">
                                  <span>
                                    iDAS整合数据分析系统
                                    <!-- <div class="explain">- 韩国某知名化妆品集团</div> -->
                                  </span>
                                </div>
                                <div class="item" style="width: 50%" @click="jumpRout('/selected-line','3')">
                                  <span>
                                    数字化产线
                                    <!-- <div class="explain">- 全球某知名饮料制造商</div> -->
                                  </span>
                                </div>
                                <div class="item" style="width: 50%" @click="jumpRout('/selected-business','3')">
                                  <span>
                                    商情诊断管理分析
                                    <!-- <div class="explain">- 中国某知名调味品生产公司</div> -->
                                  </span>
                                </div>
                                <div class="item" style="width: 50%" @click="jumpRout('/selected-5GF','3')">
                                  <span>
                                    5GF整合数据分析系统
                                    <!-- <div class="explain">- 全球某知名日用消费品公司</div> -->
                                  </span>
                                </div>
                                <div class="item" style="width: 50%" @click="jumpRout('/selected-dashboard','3')">
                                  <span>
                                    Dashboard数据实施
                                    <!-- <div class="explain">- 全球某知名乳制品公司</div> -->
                                  </span>
                                </div>
                                <div class="item" style="width: 50%" @click="jumpRout('/selected-marykay','3')">
                                  <span>
                                    市场渗透率分析
                                    <!-- <div class="explain">- 全球某化妆品巨头</div> -->
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="industry-solution-content-item" style="flex-basis: 34%;">
                              <div class="check-scheme-head" style="margin-left: 0;">医疗健康</div>
                              <div class="industry-scheme-title">
                                  <div class="item" style="width: 100%" @click="jumpRout('/selected-migration','3')">
                                    <span>
                                      数据湖迁移
                                      <!-- <div class="explain">- 美国某知名生物制药公司</div> -->
                                    </span>
                                  </div>
                                  <div class="item" style="width: 100%" @click="jumpRout('/selected-integration','3')">
                                    <span>
                                      数据湖仓一体化平台
                                      <!-- <div class="explain">- 全球某生物制药公司</div> -->
                                    </span>
                                  </div>
                                  <div class="item" style="width: 100%" @click="jumpRout('/selected-regional-indicators','3')">
                                    <span>
                                      区域指标系统
                                      <!-- <div class="explain">- 全国某领先高端靶向制剂制造商</div> -->
                                    </span>
                                  </div>
                                  <div class="item" style="width: 100%" @click="jumpRout('/selected-management-system','3')">
                                    <span>
                                      主数据管理系统
                                      <!-- <div class="explain" >- 全球某医疗和实验室产品的制造商和分销商</div> -->
                                    </span>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="industry-scheme-item industry-solution-wrap" style="margin-left:0">
                        <div class="industry-solution" style="margin-left: 10rem;">
                          <div class="industry-solution-title">产品案例</div>
                          <div class="industry-solution-content">
                            <div class="industry-solution-content-item">
                              <div class="check-scheme-head" style="margin-left: 0;">IT 服务管理</div>
                              <div class="industry-scheme-title" >
                                  <div class="item" style="width: 50%" @click="jumpRout('/produt-case-pharmaceutical','3')"><span>某大型医药企业</span></div>
                                  <div class="item" style="width: 50%" @click="jumpRout('/produt-case-metallurgy','3')"><span>某大型国有冶金集团</span></div>
                                  <div class="item" style="width: 50%" @click="jumpRout('/produt-case-technology','3')"><span>某知名传媒科技公司</span></div>
                                  <div class="item" style="width: 50%" @click="jumpRout('/produt-case-catering','3')"><span>某全国连锁餐饮企业</span></div>
                                  <div class="item" style="width: 50%" @click="jumpRout('/produt-case-retail','3')"><span>某大型零售连锁企业</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </el-submenu>
                <el-menu-item index="4" class="el-item" @click.native="jumpRout('/custom-development','4')">
                    <!-- <div class="font-style">
                        技术服务
                        <div class="list">11</div>
                    </div> -->

                    <el-dropdown placement="bottom">
                        <div class="el-dropdown-link font-style" >
                            技术服务
                        </div>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item command="a" @click.native="jumpRout('/data-warehouse','4')"><div class="data-title">数据湖仓技术</div></el-dropdown-item>
                            <el-dropdown-item command="b" ><a class="data-title" href="http://www.asialink.sh.cn/pc/home.html#">SAP实施及运维</a></el-dropdown-item>
                            <el-dropdown-item command="c" @click.native="jumpRout('/custom-development','4')"><div class="data-title">定制开发及运维</div></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-menu-item>
                <el-menu-item index="5" class="el-item" @click="jumpRout('/news','5')">新闻资讯</el-menu-item>
                <el-menu-item index="6" class="el-item" @click="jumpRout('/aboutus','6')">关于比孚</el-menu-item>
                <el-menu-item index="7" class="el-item" @click="jumpRout('/joinus','7')">加入我们</el-menu-item>
            </el-menu>
            <!-- 新闻详情 -->
            <img
              v-if="isNews"
              class="logo-style"
              style="width:15.9rem;height:5.2rem"
              src="@/assets/image/logo-黑.svg"
            />
            <el-menu v-if="isNews" :default-active="activeIndex" class="el-menustyle"  mode="horizontal" @select="handleSelect">
                <el-menu-item index="1" style="color: #000 !important" class="el-item"  @click="jumpRout('/index','1')">首页</el-menu-item>
                <el-submenu index="2" class="el-item" :style="{'border-bottom': activeIndex === '2'?'0.2rem solid #11A84F' : '0rem solid'}" @click.native="jumpRout('/industry-scheme','2')">
                    <template  slot="title"><div style="color: #000 !important">行业方案</div></template>
                    <div class="industry-scheme">
                        <div class="industry-scheme-item">
                            <div class="industry-scheme-head"  @click="jumpRout('/industry-scheme','2')"><span>快消零售</span></div>
                            <div class="industry-scheme-title" >
                                <div class="item" @click="jumpRout('/digital-production','2')"><span>数字化生产</span></div>
                                <div class="item" @click="jumpRout('/intelligent-warehousing','2')"><span>智能仓储</span></div>
                                <div class="item" @click="jumpRout('/APS-advanced-scheduling','2')"><span>APS高级排程</span></div>
                                <div class="item" @click="jumpRout('/purchase-collaboration','2')"><span>采购协同</span></div>
                                <div class="item" @click="jumpRout('/DPM-digital-Sheet','2')"><span>DPM数字化工单</span></div>
                                <div class="item" @click="jumpRout('/safety-observation','2')"><span>在线安全观察</span></div>
                            </div>
                        </div>
                         <div class="industry-scheme-item" style="margin-left:0">
                            <div class="industry-scheme-head" @click="jumpRout('/medical-health','2')"><span>医疗健康</span></div>
                            <div class="industry-scheme-title">
                                <div class="item" style="width:50%" @click="jumpRout('/sales-effectiveness','2')"><span>销售效能管理</span></div>
                                <div class="item" style="width:50%" @click="jumpRout('/academic-conference','2')"><span>学术会议管理</span></div>
                                <div class="item" style="width:50%" @click="jumpRout('/sales-operation','2')"><span>销售运营管理</span></div>
                                <div class="item" style="width:50%" @click="jumpRout('/market-access','2')"><span>市场准入与商务运营</span></div>
                            </div>
                         </div>
                    </div>
                </el-submenu>
                <el-submenu index="3" class="el-item" :style="{'border-bottom': activeIndex === '3'?'0.2rem solid #11A84F' : '0rem solid'}">
                    <template slot="title"><div style="color: #000 !important">精选案例</div></template>
                    <div class="industry-scheme">
                        <div class="check-scheme-item">
                           <div class="check-scheme-head">快消零售</div>
                            <div class="check-scheme-title">
                                <div class="item" @click="jumpRout('/selected-warehousing','3')"><span>智能仓储<div class="explain">- 全球某知名饮料制造商</div></span></div>
                                <div class="item" @click="jumpRout('/selected-iDAS','3')"><span>iDAS整合数据分析系统<div class="explain">- 韩国某知名化妆品集团</div></span></div>
                                <div class="item" @click="jumpRout('/selected-line','3')"><span>数字化产线<div class="explain">- 全球某知名饮料制造商</div></span></div>
                                <div class="item" @click="jumpRout('/selected-business','3')"><span>商情诊断管理分析<div class="explain">- 中国某知名调味品生产公司</div></span></div>
                                <div class="item" @click="jumpRout('/selected-5GF','3')"><span>5GF整合数据分析系统<div class="explain">- 全球某知名日用消费品公司</div></span></div>
                                <div class="item" @click="jumpRout('/selected-dashboard','3')"><span>Dashboard数据实施<div class="explain">- 全球某知名乳制品公司</div></span></div>
                                <div class="item" @click="jumpRout('/selected-marykay','3')"><span>市场渗透率分析<div class="explain">- 全球某化妆品巨头</div></span></div>
                            </div>
                        </div>
                        <div class="check-scheme-item">
                           <div class="check-scheme-head">医疗健康</div>
                            <div class="check-scheme-title" style="width:50%">
                                <div class="item" style="width:100%" @click="jumpRout('/selected-migration','3')"><span>数据湖迁移<div class="explain">- 美国某知名生物制药公司</div></span></div>
                                <div class="item" style="width:100%" @click="jumpRout('/selected-integration','3')"><span>数据湖仓一体化平台<div class="explain">- 全球某生物制药公司</div></span></div>
                                <div class="item" style="width:100%" @click="jumpRout('/selected-regional-indicators','3')"><span>区域指标系统<div class="explain">- 全国某领先高端靶向制剂制造商</div></span></div>
                                <div class="item" style="width:100%" @click="jumpRout('/selected-management-system','3')"><span>主数据管理系统<div class="explain" >- 全球某医疗和实验室产品的制造商和分销商</div></span></div>

                            </div>
                        </div>
                    </div>
                </el-submenu>
                <el-menu-item index="4" class="el-item" @click.native="jumpRout('/custom-development','4')">
                    <!-- <div class="font-style">
                        技术服务
                        <div class="list">11</div>
                    </div> -->

                    <el-dropdown placement="bottom">
                        <div class="el-dropdown-link font-style" style="color: #000 !important">
                            技术服务
                        </div>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item command="a" @click.native="jumpRout('/data-warehouse','4')"><div class="data-title">数据湖仓技术</div></el-dropdown-item>
                            <el-dropdown-item command="b" ><a class="data-title" href="http://www.asialink.sh.cn/pc/home.html#">SAP实施及运维</a></el-dropdown-item>
                            <el-dropdown-item command="c" @click.native="jumpRout('/custom-development','4')"><div class="data-title">定制开发及运维</div></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-menu-item>
                <el-menu-item style="color: #000 !important" index="5" class="el-item" @click="jumpRout('/news','5')">新闻资讯</el-menu-item>
                <el-menu-item style="color: #000 !important" index="6" class="el-item" @click="jumpRout('/aboutus','6')">关于比孚</el-menu-item>
                <el-menu-item style="color: #000 !important" index="7" class="el-item" @click="jumpRout('/joinus','7')">加入我们</el-menu-item>
            </el-menu>
        </div>
    </div>
</template>

<script>
import store from '@/plugins/store';
import { mapActions } from 'vuex';
export default {
    name: 'bizfocusHeader',

    data() {
        return {
            activeIndex: '1',
            isNews:false,
            showIndex: false,
            nav: null,
        };
    },
    watch:{
        activeIndexs(newValue,oldValue){
            console.log('computed更新',newValue)
            this.activeIndex = newValue
        },
        newsIndex(newValue,oldValue){
            console.log('news更新',newValue)
            this.isNews = newValue
        },
        isIndex(newValue,oldValue){
            this.showIndex = newValue
            if(this.showIndex === 1 && this.activeIndex == '1'){
                //   3.1若满足，则给nav导航添加固定样式
                this.isNews = true
                //heightNav
                this.nav.classList.add('fixedNav')
            } else {
                this.isNews = false
                //   3.2若不满足，则删除nav导航的固定样式
                this.nav.classList.remove('fixedNav')
            }
        }
    },
    computed:{
        activeIndexs(){
            console.log('computed更新 jsashuing',this.$store.state.activeIndex)
            return store.state.activeIndex;
        },
        newsIndex(){
            return store.state.newsIndex;
        },
        isIndex(){
            return store.state.isIndex;
        }
    },
    updated(){
        console.log('发生了更新')
    },
    mounted() {
        // this.isNews = true
        this.nav = document.querySelector('.big-container')
        // this.nav.classList.add('container-fixed')
        // this.activeIndex = localStorage.getItem('headId') || '1';
        // this.newsIndex =  localStorage.getItem('newsIndex') || false;
        console.log("test store",this.$store.state);
        console.log('this.$route',this.$route)
        console.log('this.newsIndex',this.newsIndex)
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex,});
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),

        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        jumpRout(route,index){
            this.activeIndex = index
            this.newsIndex = false
            // if(route,indexOf('news') != -1){
            //     this.newsIndex = true
            // }
            // localStorage.setItem('headId',index);
            // localStorage.setItem('newsIndex',this.newsIndex);
            this.$router.push(route)
            // this.activeIndex = localStorage.getItem('headId') || '1';
            this.refreshIndexInfo({
                data:this.activeIndex ,
                news:false
            });
            console.log("test store",this.$store.state);
        },
        handleJumpTo() {
            // window.location.href = 'https://visualticket.cn/'
            window.open('https://visualticket.cn/', '_blank');
        }
    },
};
</script>

<style lang="less" scoped>
.container{
    display: flex;
    width: 139rem;
    margin: auto;
}
.container-fixed {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
    width: 100%;
    background: #ffffff;
    height: 7rem;
    border-bottom: 2px solid #F8F8F8;
}
.el-menustyle{
    // width: 185rem;
    margin-left: -50rem;
    background-color: rgb(0 0 0 / 0%);
}
.industry-scheme-head{
    width: 7.2rem;
    height: 2.5rem;
    font-size: 1.8rem;
    font-family: 'CN_Medium';
    font-weight: 600;
    color: #333333;
    line-height: 2.5rem;
    text-align: justify;
    cursor: pointer;

    margin: 3.5rem 0 0 0rem;
}
.industry-scheme-title{
    width: 100%;
     /*设置当前元素为flex模式*/
     display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem 0 0 0rem;
    text-align: justify;

    .item{
        width: 33%;
        margin-top: 2rem;
        height: 3.2rem;
        font-size: 1.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #333333;
        line-height: 4rem;

        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        cursor: pointer;
        span{
            padding-bottom: 1rem;
        }
        span:hover{
            border-bottom: 0.2rem solid #11A84F;
            color: #11A84F;
            // transition: all .1s;
        }
    }
}
.industry-scheme-item{
    margin-left: 26rem;
    width: 35%;
}
.check-scheme-head{
    min-width: 7.2rem;
    height: 2.5rem;
    font-size: 1.8rem;
    font-family: 'CN_Medium';
    font-weight: 600;
    color: #333333;
    line-height: 2.5rem;

    margin: 2.9rem 0 0 26.2rem;
}
.check-scheme-item{
    width: 60%;
}
.check-scheme-item-pro{
    display: flex;
    justify-content: flex-start;
}
.check-scheme-title{
    width: 100%;
     /*设置当前元素为flex模式*/
     display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0rem 0 0 26.2rem;
    .item{
        display: flex;
        width: 50%;
        margin-top: 1rem;
        height: 3.2rem;
        font-size: 1.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #333333;
        line-height: 4rem;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        cursor: pointer;
        span{
            padding-bottom: 1rem;
            display: flex;
        }
        span:hover{
            // border-bottom: 0.2rem solid #11A84F;
            color: #11A84F;
            // transition: all .1s;
        }
    }
    .explain{
        height: 4rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #777777;
        line-height: 4rem;
        margin: 0.2rem 0 0 0.8rem;
    }
}
.font-style{
    // height: 1.8rem;
    color: #ffffff;
    font-family: 'CN_Regular';
    font-size: 1.8rem;
    // margin-top: -0.5rem;
}
.list{
        width: 21.8rem;
        height: 17rem;
        margin-top: 1.8rem;
        border: 1px solid;
    }
.font-style:hover{
    color: #11A84F;
}
.industry-solution-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.industry-solution-title {
  padding: 3rem 0;
  font-weight: 500;
  font-size: 2.4rem;
  color: #333333;
  line-height: 3.3rem;
  border-bottom: 1px solid #D8D8D8;
}
.industry-solution-content {
  display: flex;
  overflow: hidden;
  padding-bottom: 4rem;
}
.industry-solution-content-item {
  flex-shrink: 0;
}
.industry-scheme{
    min-height: 25rem;
    display: flex;
    background: #FDFDFD;
    width: 190rem;
    // @media screen and (max-width: 1920px) {
    //     width: 1900px;
    //   }
    //   @media screen and (max-width: 1746px) {
    //     width: 1745px;
    //   }
    //   @media screen and (max-width: 1536px) {
    //     width: 1516px;
    //   }
    //   @media screen and (max-width: 1280px) {
    //     width: 1270px;
    //   }
    // box-shadow: 0px 2px 15px 0px rgba(149,149,149,0.5);
    }
    /deep/ .el-menu--horizontal .el-menu-item{
        margin-left: 5rem;
        color: #ffffff;
        font-family: 'CN_Regular';
        font-size: 1.8rem;
    }
    /deep/ .el-menu--horizontal .el-menu-item:hover{
        margin-left: 5rem;
        color: #11A84F;
        font-family: 'CN_Regular';
        font-size: 1.8rem;
    }
    /deep/ .el-menu--horizontal .el-menu-item:hover{
        // margin: 0 2rem;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu.el-menu--horizontal{
        border-bottom: solid 0rem #e6e6e6;
    }
    /deep/ .el-menu--horizontal>.el-submenu{
        margin-left: 5rem;
    }
    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title{
        color: #ffffff;
        font-family: 'CN_Regular';
        font-size: 1.8rem;
    }
    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
        color: #11A84F !important;
    }
    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
        color: #000000;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        border-bottom: 0.2rem solid #11A84F;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-menu--horizontal>.el-menu-item.is-active{
        color: #fff;
    }
    /deep/ .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom: 0.2rem solid #11A84F;
        background-color: rgb(0 0 0 / 0%);
    }
    /deep/ .el-item .el-submenu .is-opened{
        // background-color: rgb(0 0 0 / 0%);
        color: #fff;
    }
    /deep/.el-icon-arrow-down:before {
        content: "";
    }
    /deep/ .el-dropdown-menu .el-popper{
        border: 1px  solid #f00;
    }
    .data-title{
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #333333;
        line-height: 4rem;
    }
    .data-title:hover{
        color: #11A84F;
    }

    .product-card{
        cursor: pointer;
    }
    .product-card:hover{
        span{
            padding-bottom: 1rem;
            border-bottom: 0.2rem solid #11A84F;
            color: #11A84F;
        }
    }
    .check-scheme-head-img{
        width: 12.8rem;
        line-height: 4.5rem;
        font-weight: 600;
        color: #333333;
        margin: 2.9rem 13.2rem 2.9rem 26.2rem;
        > img{
            height: 1.9rem;
        }
    }
    .icon-value-vticket{
        margin-left: 26.2rem;
        height: 2.5rem;
        font-family: 'CN_Medium';
        margin-left: 26.2rem;
        line-height: 2.5rem;
        font-weight: 400;
        font-size: 1.7rem;
        color: #333333;
    }
    .check-scheme-head-img-vteam{
        width: 11.7rem;
        line-height: 4.5rem;
        font-weight: 600;
        color: #333333;
        margin: 2.9rem 13.2rem 2.9rem 0;
        > img{
            height: 1.9rem;
        }
    }
    .icon-value-vteam{
        height: 2.5rem;
        font-family: 'CN_Medium';
        line-height: 2.5rem;
        font-weight: 400;
        font-size: 1.7rem;
        color: #333333;
    }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/Home.vue'
import mobile from 'is-mobile'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes: [
        { path: '/',
          redirect: '/home',
          beforeEnter: (to, from, next) => {
            mobile() ? next({ path: '/mobile-index' }) : next()
          },
        },
        { path: '/home',
          component: resolve=>(require(["./components/Home.vue"],resolve)) ,
          // component: () => import('./components/Home.vue'),
          beforeEnter: (to, from, next) => {
            mobile() ? next({ path: '/mobile-index' }) : next()
          },
          redirect: '/index',
          children:[
            {
              path: '/index',
              component: () => import('@/pages/index.vue'),
              meta: { title: '上海比孚信息科技有限公司' }
            },
            {
              //快消零售
              path: '/industry-scheme',
              component: () => import('@/pages/industryScheme.vue'),
              meta: { title: '快消零售' }
            },
            {
              //医疗健康
              path: '/medical-health',
              component: () => import('@/pages/medicalHealth.vue'),
              meta: { title: '医疗健康' }
            },{
              path: '/purchase-collaboration',
              component: () => import('@/pages/purchaseCollaboration.vue'),
              meta: { title: '采购协同' }
            },
            {
              path: '/DPM-digital-Sheet',
              component: () => import('@/pages/DPMDigitalSheet.vue'),
              meta: { title: 'DPM数字化工单' }
            },
            {
              path: '/digital-production',
              component: () => import('@/pages/digitalProduction.vue'),
              meta: { title: '数字化生产' }
            },
            {
              path: '/APS-advanced-scheduling',
              component: () => import('@/pages/APSAdvancedScheduling.vue'),
              meta: { title: 'APS高级排程' }
            },
            {
              path: '/intelligent-warehousing',
              component: () => import('@/pages/intelligentWarehousing.vue'),
              meta: { title: '智能仓储' }
            },
            {
              path: '/safety-observation',
              component: () => import('@/pages/safetyObservation.vue'),
              meta: { title: '在线安全观察' }
            },
            {
              //销售效能管理
              path: '/sales-effectiveness',
              component: () => import('@/pages/salesEffectiveness.vue'),
              meta: { title: '销售效能管理' }
            },
            {
              //销售运营管理
              path: '/sales-operation',
              component: () => import('@/pages/salesOperation.vue'),
              meta: { title: '销售运营管理' }
            },
            {
              //学术会议管理
              path: '/academic-conference',
              component: () => import('@/pages/academicConference.vue'),
              meta: { title: '学术会议管理' }
            },
            {
              //市场准入与商务运营
              path: '/market-access',
              component: () => import('@/pages/marketAccess.vue'),
              meta: { title: '市场准入与商务运营' }
            },
            {
              //案例精选
              path: '/selected-warehousing',
              component: () => import('@/pages/selected/selectedWarehousing.vue'),
              meta: { title: '精选案例-智能仓储' }
            },
            {
              path: '/selected-marykay',
              component: () => import('@/pages/selected/selectedMarykay.vue'),
              meta: { title: '精选案例-市场渗透率分析' }
            },
            {
              path: '/selected-iDAS',
              component: () => import('@/pages/selected/selectIDAS.vue'),
              meta: { title: '精选案例-iDAS整合数据分析系统' }
            },{
              path: '/selected-migration',
              component: () => import('@/pages/selected/selectMigration.vue'),
              meta: { title: '精选案例-数据胡迁移' }
            },{
              path: '/selected-integration',
              component: () => import('@/pages/selected/selectedIntegration.vue'),
              meta: { title: '精选案例-数据湖仓一体化平台' }
            },{
              path: '/selected-regional-indicators',
              component: () => import('@/pages/selected/selectedRegionalIndicators.vue'),
              meta: { title: '精选案例-区域指标系统' }
            },{
              path: '/selected-management-system',
              component: () => import('@/pages/selected/selectedManagementSystem.vue'),
              meta: { title: '精选案例-主数据管理系统' }
            },{
              path: '/selected-business',
              component: () => import('@/pages/selected/selectedBusiness.vue'),
              meta: { title: '精选案例-商情诊断管理分析' }
            },{
              path: '/selected-dashboard',
              component: () => import('@/pages/selected/selectedDashboard.vue'),
              meta: { title: '精选案例-Dashboard数据实施' }
            },{
              path: '/selected-5GF',
              component: () => import('@/pages/selected/selected-5GF.vue'),
              meta: { title: '精选案例-5GF整合数据分析系统' }
            },{
              path: '/selected-line',
              component: () => import('@/pages/selected/selectedLine.vue'),
              meta: { title: '精选案例-数字化产线' }
            },{
              path: '/aboutus',
              component: () => import('@/pages/aboutUs.vue'),
              meta: { title: '关于比孚' }
            },{
              path: '/joinus',
              component: () => import('@/pages/joinUs.vue'),
              meta: { title: '加入我们' }
            },{
              path: '/news',
              component: () => import('@/pages/news.vue'),
              meta: { title: '新闻资讯' }
            },
            {
              path: '/news-detail',
              component: () => import('@/pages/news-more/news-detail.vue'),
              meta: { title: '新闻详情' }
            },{
              path: '/news-1',
              component: () => import('@/pages/news-more/news-1.vue'),
            },{
              path: '/news-2',
              component: () => import('@/pages/news-more/news-2.vue'),
            },{
              path: '/news-3',
              component: () => import('@/pages/news-more/news-3.vue'),
            },{
              path: '/news-4',
              component: () => import('@/pages/news-more/news-4.vue'),
            },{
              path: '/news-5',
              component: () => import('@/pages/news-more/news-5.vue'),
            },{
              //技术服务
              path: '/custom-development',
              component: () => import('@/pages/technicalService/customDevelopment.vue'),
              meta: { title: '定制开发及运维' }
            },{
              //快速交付
              path: '/fast-delivery',
              component: () => import('@/pages/technicalService/fastDelivery.vue'),
              meta: { title: '快速交付平台' }
            },{
              //数据湖仓
              path: '/data-warehouse',
              component: () => import('@/pages/technicalService/dataWarehouse.vue'),
              meta: { title: '数据湖仓技术' }
            },
            // 服务体系
            {
              path: '/service-system',
              component: () => import('@/pages/serviceSystem.vue'),
              meta: { title: 'IT 服务平台' }
            },
            // 产品案例 - 某知名传媒科技公司
            {
              path: '/produt-case-technology',
              component: () => import('@/pages/productCase/technology.vue'),
              meta: { title: '产品案例-某知名传媒科技公司' }
            },
            // 产品案例 - 某大型国有冶金集团
            {
              path: '/produt-case-metallurgy',
              component: () => import('@/pages/productCase/metallurgy.vue'),
              meta: { title: '产品案例-某大型国有冶金集团' }
            },
            // 产品案例 - 某大型医药企业
            {
              path: '/produt-case-pharmaceutical',
              component: () => import('@/pages/productCase/pharmaceutical.vue'),
              meta: { title: '产品案例-某大型医药企业' }
            },
            // 产品案例 - 某全国连锁餐饮企业
            {
              path: '/produt-case-catering',
              component: () => import('@/pages/productCase/catering.vue'),
              meta: { title: '产品案例-某全国连锁餐饮企业' }
            },
            // 产品案例 - 某大型零售连锁企业
            {
              path: '/produt-case-retail',
              component: () => import('@/pages/productCase/retail.vue'),
              meta: { title: '产品案例-某大型零售连锁企业' }
            },
          ],
        },
        {
          path:'/mobile-index',
          // component: () => import('@/pages/mobile/mobile-index.vue'),
          component: resolve=>(require(["@/pages/mobile/mobile-index.vue"],resolve)) ,
          beforeEnter: (to, from, next) => {
            mobile() ? next() : next({ path: '/index' })
          },
        },
        {
          path:'/mobile-programme-sale',
          component: () => import('@/pages/mobile/mobile-programme-sale.vue'),
        },
        {
          path:'/mobile-programme-medical',
          component: () => import('@/pages/mobile/mobile-programme-medical.vue'),
        },
        //手机端行业方案
        {
          path:'/mobile-programme-APS',
          component: () => import('@/pages/mobile/mobile-programme-APS.vue'),
        },
        {
          path:'/mobile-programme-DPM',
          component: () => import('@/pages/mobile/mobile-programme-DPM.vue'),
        },
        {
          path:'/mobile-programme-efficiency',
          component: () => import('@/pages/mobile/mobile-programme-efficiency.vue'),
        },
        {
          path:'/mobile-programme-line',
          component: () => import('@/pages/mobile/mobile-programme-line.vue'),
        },
        {
          path:'/mobile-programme-market',
          component: () => import('@/pages/mobile/mobile-programme-market.vue'),
        },
        {
          path:'/mobile-programme-observation',
          component: () => import('@/pages/mobile/mobile-programme-observation.vue'),
        },
        {
          path:'/mobile-programme-operate',
          component: () => import('@/pages/mobile/mobile-programme-operate.vue'),
        },
        {
          path:'/mobile-programme-purchase',
          component: () => import('@/pages/mobile/mobile-programme-purchase.vue'),
        },
        {
          path:'/mobile-programme-science',
          component: () => import('@/pages/mobile/mobile-programme-science.vue'),
        },
        {
          path:'/mobile-programme-warehousing',
          component: () => import('@/pages/mobile/mobile-programme-warehousing.vue'),
        },
        //------------------------案例精选----------------------------------------------
        {
          path:'/mobile-case-warehousing',
          component: () => import('@/pages/mobile/mobile-case-warehousing.vue'),
        },
        {
          path:'/mobile-case-5GF',
          component: () => import('@/pages/mobile/mobile-case-5GF.vue'),
        },
        {
          path:'/mobile-case-business',
          component: () => import('@/pages/mobile/mobile-case-business.vue'),
        },
        {
          path:'/mobile-case-dashboard',
          component: () => import('@/pages/mobile/mobile-case-dashboard.vue'),
        },
        {
          path:'/mobile-case-indicators',
          component: () => import('@/pages/mobile/mobile-case-indicators.vue'),
        },
        {
          path:'/mobile-case-integrated',
          component: () => import('@/pages/mobile/mobile-case-integrated.vue'),
        },
        {
          path:'/mobile-case-lake',
          component: () => import('@/pages/mobile/mobile-case-lake.vue'),
        },
        {
          path:'/mobile-case-line',
          component: () => import('@/pages/mobile/mobile-case-line.vue'),
        },
        {
          path:'/mobile-case-masterdata',
          component: () => import('@/pages/mobile/mobile-case-masterdata.vue'),
        },
        {
          path:'/mobile-case-permeability',
          component: () => import('@/pages/mobile/mobile-case-permeability.vue'),
        },
        {
          path:'/mobile-case-iDAS',
          component: () => import('@/pages/mobile/mobile-case-iDAS.vue'),
        },
        //-----------------技术服务----------------------------------
        {
          path:'/mobile-service-customized',
          component: () => import('@/pages/mobile/mobile-service-customized.vue'),
        },
        {
          path:'/mobile-service-lake',
          component: () => import('@/pages/mobile/mobile-service-lake.vue'),
        },
        {
          path:'/mobile-service-delivery',
          component: () => import('@/pages/mobile/mobile-service-delivery.vue'),
        },
        //新闻资讯
        {
          path: '/mobile-news-detail/:id',
          component: () => import('@/pages/mobile/mobile-news-detail.vue'),
        },
        {
          path:'/mobile-news-index',
          component: () => import('@/pages/mobile/mobile-news-index.vue'),
        },
        {
          path:'/mobile-news-detail1',
          component: () => import('@/pages/mobile/mobile-news-detail1.vue'),
        },
        {
          path:'/mobile-news-detail2',
          component: () => import('@/pages/mobile/mobile-news-detail2.vue'),
        },
        {
          path:'/mobile-news-detail3',
          component: () => import('@/pages/mobile/mobile-news-detail3.vue'),
        },
        {
          path:'/mobile-news-detail4',
          component: () => import('@/pages/mobile/mobile-news-detail4.vue'),
        },
        {
          path:'/mobile-news-detail5',
          component: () => import('@/pages/mobile/mobile-news-detail5.vue'),
        },
        //---------------关于比孚--------------------------------------
        {
          path:'/mobile-aboutus-index',
          component: () => import('@/pages/mobile/mobile-aboutus-index.vue'),
        },
        //---------------加入比孚----------------------------------------
        {
          path:'/mobile-joinus-index',
          component: () => import('@/pages/mobile/mobile-joinus-index.vue'),
        },
        // 服务体系
        {
          path: '/mobile-service-system',
          component: () => import('@/pages/mobile/mobile-service-system.vue'),
        },
        // 产品案例 - 某知名传媒科技公司
        {
          path: '/mobile-produt-case-technology',
          component: () => import('@/pages/mobile/mobile-produt-case-technology.vue'),
        },
        // 产品案例 - 某大型国有冶金集团
        {
          path: '/mobile-produt-case-metallurgy',
          component: () => import('@/pages/mobile/mobile-produt-case-metallurgy.vue'),
        },
        // 产品案例 - 某大型医药企业
        {
          path: '/mobile-produt-case-pharmaceutical',
          component: () => import('@/pages/mobile/mobile-produt-case-pharmaceutical.vue'),
        },
        // 产品案例 - 某全国连锁餐饮企业
        {
          path: '/mobile-produt-case-catering',
          component: () => import('@/pages/mobile/mobile-produt-case-catering.vue'),
        },
        // 产品案例 - 某大型零售连锁企业
        {
          path: '/mobile-produt-case-retail',
          component: () => import('@/pages/mobile/mobile-produt-case-retail.vue'),
        },
      ]
})

//挂载路由导航守卫
// router.beforeEach((to,from,next) => {
//   if(to.path === '/login') return next()
//   //获取token
//   const tokenStr = window.sessionStorage.getItem('token')
//   //没有token，强制跳转到登录页
//   if(!tokenStr) return next('/login')

//   next()
// })
router.beforeEach((to, from, next) => {
     // 让页面回到顶部
     document.documentElement.scrollTop = 0
     //不同路由设置不同标题
     const title = to.meta.title
      if (title) {
        document.title = title
      }else{
        document.title = '上海比孚信息科技有限公司'
      }
     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
 })
export default router

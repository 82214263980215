<template>
    <div class="footer-container">
        <div class="contain-1">
            <img
              class="contain1-logo"
              src="@/assets/image/logo.svg"
            />
            <div class="box1">
                <img class="img" src="@/assets/image/footer/地址.png"/>
                <div class="content">上海市杨浦区纪念路8号 财大科技园7号楼208室</div>
            </div>
            <div class="box2">
                <img class="img" src="@/assets/image/footer/电话@2x.png"/>
                <div class="content">021-65681880</div>
            </div>
            <div class="box3">
                <img class="img" src="@/assets/image/footer/邮箱@2x.png"/>
                <div class="content">marketing@bizfocus.cn</div>
            </div>
        </div>
        <div class="contain-2">
            <div class="title" @click="jumpRout('/index','1')">首页</div>
        </div>
        <div class="contain-3">
            <div class="title" @click="jumpRout('/industry-scheme','2')">行业方案</div>
            <div class="content" style="margin-top:2rem" @click="jumpRout('/industry-scheme','2')">快消零售</div>
            <div class="content" @click="jumpRout('/medical-health','2')">医疗健康</div>
        </div>
        <div class="contain-3">
            <div class="title">精选案例</div>
            <div class="content" style="margin-top:2rem" @click="jumpRout('/selected-warehousing','3')">快消零售</div>
            <div class="content" @click="jumpRout('/selected-migration','3')">医疗健康</div>
        </div>
        <div class="contain-3">
            <div class="title" @click="jumpRout('/custom-development','4')">技术服务</div>
            <div class="content" style="margin-top:2rem" @click="jumpRout('/data-warehouse','4')">数据湖仓技术</div>
            <div class="content" @click="gotoOutLine">SAP实施及运维</div>
            <div class="content" @click="jumpRout('/custom-development','4')">定制开发及运维</div>
        </div>
        <div class="contain-3">
            <div class="title" @click="jumpRout('/news','5')">新闻资讯</div>
        </div>
        <div class="contain-3">
            <div class="title" @click="jumpRout('/aboutus','6')">关于比孚</div>
        </div>
        <div class="contain-3">
            <div class="title" @click="jumpRout('/joinus','7')">加入我们</div>
        </div>
        <div class="contain-4">
            <img src="@/assets/image/footer/公众号@2x.png" class="contain4-img"/>
            <div class="content">关注我们</div>
        </div>
        <div class="contain-5">© 上海比孚信息科技有限公司版权所有 2023<div class="line"></div><div class="title1">沪ICP备13022622号-1</div></div>
    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesFooter',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        gotoOutLine(){
            window.location.href = 'http://www.asialink.sh.cn/pc/home.html#'
        },
        jumpRout(route,index){
            this.activeIndex = index
            this.newsIndex = false
            // if(route,indexOf('news') != -1){
            //     this.newsIndex = true
            // }
            localStorage.setItem('headId',index);
            // localStorage.setItem('newsIndex',this.newsIndex);
            this.$router.push(route)
            this.activeIndex = localStorage.getItem('headId') || '1';
            this.refreshIndexInfo({
                data:this.activeIndex ,
                news:false
            });
            console.log("test store",this.$store.state);
        }
    },
};
</script>

<style lang="less" scoped>
.footer-container{
    width: 100%;
    height: 33rem;
    background: #1E1E37;
    display: flex;
}
.contain-1{
    margin: 6.5rem 0 0 20.3rem;
    width: 49.3rem;
    .box1{
        display: flex;
        margin-top: 3.5rem;
        .img{
            width: 1.6rem;
            height: 1.6rem;
        }
        .content{
            font-size: 1.4rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.6rem;
            margin: -1rem 0 0 0.7rem;
        }
    }
    .box2{
        display: flex;
        margin-top: 1rem;
        .img{
            width: 1.6rem;
            height: 1.6rem;
        }
        .content{
            font-size: 1.4rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.6rem;
            margin: -1rem 0 0 0.7rem;
        }
    }
    .box3{
        display: flex;
        margin-top: 1rem;
        .img{
            width: 1.6rem;
            height: 1.6rem;
        }
        .content{
            font-size: 1.4rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.6rem;
            margin: -1rem 0 0 0.7rem;
        }
    }
}
.contain-2{
    margin-top: 6.6rem;
    .title{
        width: 3.6rem;
        height: 1.6rem;
        font-size: 1.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 2.4rem;
    }
    .title:hover{
        color: #11A84F;
        cursor: pointer;
    }
}
.contain-3{
    margin: 6.6rem 0 0 6.3rem;
    .title{
        height: 1.6rem;
        font-size: 1.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 2.4rem;
    }
    .content{
        font-size: 1.4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #858A9D;
        line-height: 3.2rem;
    }
    .content:hover,.title:hover{
        color: #11A84F;
        cursor: pointer;
    }
}
.contain-4{
    margin: 6.6rem 0 0 6.4rem;
    .contain4-img{
        width: 10rem;
        height: 10rem;
    }
    .content{
        height: 3.6rem;
        font-size: 1.4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 3.6rem;
        text-align: center;
    }
}
.contain1-logo{
    width: 16rem;
    height: 5.2rem;
}
.contain-5{
    position: absolute;
    margin: 26.1rem 0 0 68.9rem;
    height: 3.6rem;
    font-size: 1.4rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #858A9D;
    line-height: 3.6rem;
    display: flex;
    .line{
        width: 1px;
        height: 1.2rem;
        border: 1px solid #424655;

        margin:1.1rem 0 0 2.6rem;
    }
    .title1{
        height: 3.6rem;
        font-size: 1.4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #858A9D;
        line-height: 3.6rem;
        margin:-0.2rem 0 0  1.9rem;
    }
}
</style>
